
<template>
    <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <div class="box box-primary">
                <div class="box-header with-border">
                    <h3 class="box-title">Přidat zaměstnance</h3>
                </div>
                <form role="form">
                    <div class="box-body" style="padding-left: 0; padding-right: 0">
                        <div class="col-md-12" style="padding: 0">
                            <div class="col-md-6">
                                <div :class="{'has-error': $v.formResponses.forename.$error}">
                                    <label for="inputName" class="control-label">Křestní jméno</label>
                                    <input id="inputName" class="form-control" v-model="$v.formResponses.forename.$model" type="text">
                                    <span v-if="!$v.formResponses.forename.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.forename.maxLength" class="help-block">Může mít maximálně {{$v.formResponses.forename.$params.maxLength.max}} znaků!</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div :class="{'has-error': $v.formResponses.surname.$error}">
                                    <label for="inputName" class="control-label">Příjmení</label>
                                    <input id="inputName" class="form-control" v-model="$v.formResponses.surname.$model" type="text">
                                    <span v-if="!$v.formResponses.surname.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.surname.maxLength" class="help-block">Může mít maximálně {{$v.formResponses.surname.$params.maxLength.max}} znaků!</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div :class="{'has-error': $v.formResponses.email.$error}">
                                <label for="inputName" class="control-label">Email</label>
                                <input id="inputName" class="form-control" v-model.lazy="$v.formResponses.email.$model" type="text">
                                <span v-if="!$v.formResponses.email.required" class="help-block">Povinný údaj</span>
                                <span v-if="!$v.formResponses.email.email" class="help-block">E-mailová adresa musí mít formát emailu!</span>
                                <span v-if="!$v.formResponses.email.maxLength" class="help-block">E-mailová adresa může mít maximálně {{$v.formResponses.email.$params.maxLength.max}} znaků!</span>
                            </div>
                        </div> 
                    </div>
                </form>
            </div>
            <div class="box box-primary">
                <div class="box-footer">
                    <button @click="submitForm" type="button" class="btn btn-success pull-left" :disabled="$v.formResponses.$invalid" style="margin-right: 10px">Přidat</button>
                    <div class="checkbox pull-left">
                        <label>
                            <input v-model="dontClosePage" type="checkbox"> Budu přidávat dál                       
                        </label>
                    </div>
                    <button type="button" class="btn btn-default pull-right" @click="closeTab(page.id)">Zrušit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const name = 'EmployeeAdd';

// Vuelidate
import { required, maxLength, email } from 'vuelidate/lib/validators'

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        return {
            page: null,

            formResponses: {
                forename: '',
                surname: '',
                email: '',
            }
        }
    },
    validations: {
        formResponses: {
            forename: {
                required,
                maxLength: maxLength(16)
            },
            surname: {
                required,
                maxLength: maxLength(16)
            },
            email: {
                required,
                email,
                maxLength: maxLength(32)
            }
        }
    },
    computed: mapGetters(["getAllPages"]),
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "removeTab", 'closeTab', 'addEmployee']),
        async submitForm()
        {            
            if (this.$v.formResponses.$invalid) 
            {
                return 0;
            }

            this.errors = [];
            
            const values = {
                email: this.formResponses.email,
                forename: this.formResponses.forename,
                surname: this.formResponses.surname
            }

            const res = await this.addEmployee(values);

            if (res.code == "EMPLOYEE_ADDED")
            {
                this.$alerts.toast('Záznam byl úspěšně vytvořen. Přihlašovací údaje byly zaslány na zadanou emailovou adresu.', 'success');

                this.closeTab(this.page.id);
            }
            else if (res.code == "EMAIL_INUSE")
            {
                this.$alerts.message('Účet se zadanou emailovou adresou již existuje.', "", 'error');
            }
            else
            {
                this.$alerts.message('Došlo k chybě při vytváření záznamu.', "", 'error');
            }
        }
    },
    async created()
    {
        this.page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(this.page);
        this.makeActiveTab(this.page);
    }
}
</script>